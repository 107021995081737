<template>
	<div>
		<div class="detail-title">
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>内容</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ path: '/excerpt' }"
					>素材</el-breadcrumb-item
				>
				<el-breadcrumb-item>新增</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div class="detail-info">
			<el-form ref="form" :model="form" :rules="rules" label-width="120px" size="mini">
				<el-form-item label="标题：" label-position="left" class="input" prop="title">
					<el-input style="width: 200px" v-model="form.title"></el-input>
				</el-form-item>

				<el-form-item label="封面：" label-position="left" prop="pic">
					<select-images @onSelect="onSelect" @onDelete="onDelete" :image="image"></select-images>
				</el-form-item>

				<!-- <el-form-item label="标签：" label-position="left" class="input">
					<el-tag
						:key="tag"
						v-for="tag in form.tags"
						closable
						:disable-transitions="false"
						@close="handleClose(tag)"
					>
						{{ tag }}
					</el-tag>
					<el-input
						class="input-new-tag"
						v-if="inputVisible"
						v-model="inputValue"
						ref="saveTagInput"
						size="small"
						@keyup.enter.native="handleInputConfirm"
						@blur="handleInputConfirm"
					>
					</el-input>
					<el-button
						v-else
						class="button-new-tag"
						size="small"
						@click="showInput"
						>+ 添加标签</el-button
					>
				</el-form-item> -->
				<el-form-item label="年级：" label-position="left" class="input" prop="selectLevelAndGrade">
				    <el-cascader
				        style="width: 200px"
						v-model="form.selectLevelAndGrade"
				        :options="options"
				        :props="{ expandTrigger: 'hover' }"
						@change="changeLevelAndGrade"
				      ></el-cascader>
				</el-form-item>
				
				<el-form-item label="选择分类：" prop="category">
				    <el-select v-model="form.category" clearable placeholder="选择分类" size="small">
				        <el-option
				                v-for="item in categorys"
				                :key="item.id"
				                :label="item.label"
				                :value="item.id">
				        </el-option>
				    </el-select>
				</el-form-item>
				
				<el-form-item label="选择学期：" prop="semester">
				    <el-select v-model="form.semester" clearable placeholder="选择学期" size="small">
				        <el-option label="上册" :value="1"></el-option>
				        <el-option label="下册" :value="2"></el-option>
				    </el-select>
				</el-form-item>

				<el-form-item label="内容：" label-position="left" prop="content">
					<editor @onChange="onChange" :content="form.content"></editor>
				</el-form-item>

				<el-form-item label="状态：" label-position="left" class="input">
					<el-switch v-model="form.status" @change="setStatus"> </el-switch>
					<span style="margin-left: 5px; font-size: 12px">{{ statusTxt }}</span>
				</el-form-item>

				<el-form-item>
					<el-button
						size="small"
						type="primary"
						:loading="loading"
						@click="checkOnSubmit"
						>保存</el-button
					>
					<router-link to="/excerpt" class="router-link"
						><el-button style="margin-left: 10px" size="small"
							>取消</el-button
						></router-link
					>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
import cover from '../../../components/cover'
import editor from '../../../components/editor'
import { mapActions } from 'vuex'
import SelectImages from "../../../components/selectImages/index";
export default {
	name: 'Add',
	components: {SelectImages,  editor },
	data() {
		return {
			statusTxt: '上架',
			loading: false,
			inputVisible: false,
			inputValue: '',
			uploadPath: 'excerpt',
			image:'',
			form: {
				status: true,
				category:'',
				levelId:'',
				gradeId:'',
				semester: '',
				pic: '',
				title: '',
				content: '',
				// tags: [],
				selectLevelAndGrade:[]
			},
			options: [],
			categorys:[],
			// 表单验证
			rules: {
				  title: [
					{ required: true, message: '标题必须', trigger: 'blur' }
				  ],
				  pic: [
					  { required: true, message: '封面图必须', trigger: 'blur' }
				  ],
				  semester: [
					   { required: true, message: '学期必须', trigger: 'blur' }
				  ],
				  category: [
					   { required: true, message: '分类必须', trigger: 'blur' }
				  ],
				  selectLevelAndGrade: [
					   { required: true, message: '年级必须', trigger: 'blur' }
				  ],
				  content: [
					   { required: true, message: '内容必须', trigger: 'blur' }
				  ]
			}
		}
	},
	methods: {
		...mapActions('excerpt', ['addExcerpt','getBasicData']),
		uploadSuccess(path) {
			this.form.pic = path
		},
		checkOnSubmit(){
			this.$refs['form'].validate((valid) => {
				  if (valid) {
					this.onSubmit()
				  } else {
					return false;
				  }
				})
		},
		async onSubmit() {
			let _this = this
			this.loading = true
			await this.addExcerpt(this.form).then(res => {
				if (res.ret == 0) {
					_this.$message.success('新增成功！')
					_this.$router.push('/excerpt')
				}
			})
			this.loading = false
		},
		setStatus(status) {
			if (this.form.status) {
				this.statusTxt = '上架'
			} else {
				this.statusTxt = '下架'
			}
			this.form.status = status
		},
		handleClose(tag) {
			this.form.tags.splice(this.form.tags.indexOf(tag), 1)
		},

		showInput() {
			this.inputVisible = true
			this.$nextTick(() => {
				this.$refs.saveTagInput.$refs.input.focus()
			})
		},
		handleInputConfirm() {
			let inputValue = this.inputValue
			if (inputValue) {
				this.form.tags.push(inputValue)
			}
			this.inputVisible = false
			this.inputValue = ''
		},
		onChange(content) {
			this.form.content = content
		},
		changeLevelAndGrade(e){
			this.form.levelId = e[0]
			this.form.gradeId = e[1]
			this.selectLevelAndGrade = e
		},
		onSelect(image){
			this.image =  imageUrl + image.path
			this.form.pic = image.path
		},
		onDelete(){
			this.image = ''
		},
	},
	async mounted() {
		let levels = []
		let grades = []
		let options = []
		await this.getBasicData().then(res => {
			this.categorys = res.data.category
		    levels = res.data.levels
			grades = res.data.grades
		})
		
		levels.forEach(level => {
			let children = []
			grades.forEach(grade => {
				if(grade.level_id == level.id){
					children.push({
						value: grade.id,
						label: grade.name
					})
				}
			})
			options.push({
				value:level.id,
				label:level.name,
				children:children
			})
		})
		this.options = options
	}
}
</script>

<style>
.el-tag + .el-tag {
	margin-left: 10px;
}
.button-new-tag {
	margin-left: 10px;
	height: 32px;
	line-height: 30px;
	padding-top: 0;
	padding-bottom: 0;
}
.input-new-tag {
	width: 90px;
	margin-left: 10px;
	vertical-align: bottom;
}
</style>
